.Homepage {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
background-image: url('./../../assets/images/back.jpg');
  background-size: cover;
  background-position: center;
  min-height: 90vh;
  overflow-x: hidden;
  
  /* background-color: black; */
}

img {
  width: 100%;
  max-width: 30rem;
  height: auto;
}

#code{
  font-size: 25px;
  color: #808080;
}
.logo-home {
  margin-top: 2rem;
  width: 100%;
  max-width: 25rem;
}

.home-text {
  text-align: center;
}

.home-text h1 {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(156, 152, 152);
}

.logo-text {
  width: 100%;
  max-width: 35rem;
}

.signin-button {
  background-color: red;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

#mob-button{
  display:none;
}

@media (max-width: 768px) {
  .logo-home {
    max-width: 150px;
  }
  

  .home-text h1 {
    font-size: 15px;
  }
}

@media screen and (max-width: 940px) {
  #mob-button{
    display: inline;
    margin-bottom: 2.4rem;
  }
}