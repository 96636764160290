.sponsor-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 2rem auto; /* Adjust margin to center the grid horizontally */
  max-width: 1200px; /* Optionally, set a max-width for the grid */
}

.sponsor-wrapper {
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center; /* Center the logo horizontally */
  align-items: center; /* Center the logo vertically */
}

.sponsor-wrapper:hover {
  transform: translateY(-5px);
}

.sponsor-container a {
  display: block;
  width: 100%;
  height: 100%;
}
#prize{
  color: black;
}
.sponsor {
  max-width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .sponsor-grid {
      margin: 2rem; /* Adjust margin for smaller screens */
  }
}
