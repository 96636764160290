.About {
    background-image: url('./../../assets/images/library.jpg');
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    gap: 5rem;
    padding: 2rem 7rem;
    

    & .where{
        background-color: #1b5511;
        text-align: center;
        
        & p {
            text-align: center;
            color:antiquewhite
        }
    }

    & .about-event{
        background-color: #8f1519;
        text-align: center;
        & p{
            text-align: justify;
            color:antiquewhite
        }
    }

    & .when{
        background-color: #003462;
        text-align: center;
        & p {
            text-align: center;
            color:antiquewhite
        }
    }



    & .about-data {
        clip-path: polygon(50% 100%, 100% 62%, 100% 0, 0 0, 0 62%);
        /* background-color: yellow; */
        padding: 5rem;
        padding-bottom: 10rem;
        opacity: 90%;
        color: white;
        

        & h3 {
            font-size: 2.4rem;
            color: aliceblue;
        }

        & p {
            font-size: 1.6rem;
            
        }
    }

    & .about-event {
        padding-bottom: 15rem;
    }

    /* & .about-data::before{
        content: " ";
        clip-path: polygon(50% 100%, 100% 62%, 100% 0, 0 0, 0 62%);
        background-color: black;
        padding: 5rem;
        padding-bottom: 10rem;
        text-align: center;
    } */
}


@media (width <=850px) {
    .About {
        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr;

        & .about-data {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            & h3 {
                font-size: 1.6rem;
            }

            & p {
                font-size: 1rem;
            }
        }
    }

}