@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
#spinner{
  align-items: center;
  background-color: #866327;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary: #6948ff;
  --secondary: #553bcb;
  --darkgrey: #22262a;
  --lightgrey: #d3d3d3;
  --grey: #808080;
  --white: #f8f8f8;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--white);
  
}

body {
  /* color: #333;
  font-family: 'Montserrat', sans-serif; */
  line-height: 1.5;
  overflow-x: hidden;
  /* background: rgb(0,0,6);
  background: linear-gradient(90deg, rgba(0,0,6,1) 0%, rgba(87,11,11,1) 100%); */
 
}

.primary-color {
  color: var(--primary);
}
  /* 
@keyframes animate {
  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }
  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #3c5ec3;
  overflow: hidden;
}
.background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 13s linear infinite;
}




.background li:nth-child(0) {
  left: 2%;
  width: 167px;
  height: 167px;
  bottom: -167px;
  animation-delay: 1s;
}
.background li:nth-child(1) {
  left: 5%;
  width: 114px;
  height: 114px;
  bottom: -114px;
  animation-delay: 2s;
}
.background li:nth-child(2) {
  left: 26%;
  width: 118px;
  height: 118px;
  bottom: -118px;
  animation-delay: 8s;
}
.background li:nth-child(3) {
  left: 40%;
  width: 166px;
  height: 166px;
  bottom: -166px;
  animation-delay: 3s;
}
.background li:nth-child(4) {
  left: 15%;
  width: 159px;
  height: 159px;
  bottom: -159px;
  animation-delay: 6s;
}
.background li:nth-child(5) {
  left: 16%;
  width: 100px;
  height: 100px;
  bottom: -100px;
  animation-delay: 5s;
}
.background li:nth-child(6) {
  left: 67%;
  width: 115px;
  height: 115px;
  bottom: -115px;
  animation-delay: 2s;
}
.background li:nth-child(7) {
  left: 54%;
  width: 138px;
  height: 138px;
  bottom: -138px;
  animation-delay: 9s;
}
.background li:nth-child(8) {
  left: 1%;
  width: 135px;
  height: 135px;
  bottom: -135px;
  animation-delay: 17s;
}
.background li:nth-child(9) {
  left: 69%;
  width: 151px;
  height: 151px;
  bottom: -151px;
  animation-delay: 25s;
} */