.navbar {
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 1rem;
    background-color: var(--darkgrey);


}
.scroll-animation {
    animation-name: scrollEffect;
    animation-duration: 1s;
}

@keyframes scrollEffect {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.logo {
    & .main-logo {
        display: flex;

        & .logo-image {
            width: auto;
            height: 5rem;
        }

        & .text-image {
            max-width: 15rem;
            height: auto;
        }
    }
}

.nav-menu {
    display: flex;
    align-items: center;
}

.nav-item {
    padding: 1rem;
    font-size: medium;
}

.hamburger {
    display: none;
}

.signin-button {
    background-color: red;
    color: white;
    font-size: 1.4rem;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.nav-item-wrapper {
    display: flex;
    align-items: center;
}

.nav-item-wrapper a {
    margin-right: 1rem;
    /* Adjust the margin as needed */
}

@media screen and (max-width: 940px) {
    .nav-menu {
        position: fixed;
        top: 0;
        z-index: 1;
        left: -100%;
        flex-direction: column;
        justify-content: center;
        background-color: var(--darkgrey);
        width: 100%;
        height: 100%;
        text-align: center;
        transition: 0.4s;

    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem;
        font-size: 1.8rem;
        justify-content: center;
    }

    .hamburger {
        display: flex;
        z-index: 1;
    }
}
