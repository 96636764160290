.ContactUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2.4rem;

    & h4 {
        font-size: 3.6rem;
        margin: 1rem 0;
    }

    & p {
        font-size: 2.4rem;
        margin-bottom: 1rem;
    }

    & .seprater {
        width: 10rem;
        height: 1rem;
        background-color: black;
        margin: 1rem 0;
        margin-bottom: 2rem;
        border-radius: 3rem;
        -webkit-border-radius: 3rem;
        -moz-border-radius: 3rem;
        -ms-border-radius: 3rem;
        -o-border-radius: 3rem;
    }

    & .contact-details {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20rem;
        margin-bottom: 2rem;

        & .contact-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
            padding: 2.5rem 0;
            /* width: 40rem; */
            border-radius: 1.5rem;
            -webkit-border-radius: 1.5rem;
            -moz-border-radius: 1.5rem;
            -ms-border-radius: 1.5rem;
            -o-border-radius: 1.5rem;

            & h5 {
                font-size: 2.4rem;
                margin-bottom: 1rem;
            }

            & p {
                font-size: 1.6rem;

                & a {
                    color: black;
                }
            }
        }
    }

    & .form {
        width: 70%;
        display: flex;
        flex-direction: column;

        & .two-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
        }

        & .intag {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;

            & label {
                font-size: 2rem;
            }

            & input {
                height: 3rem;
                font-size: 1.6rem;
                border: .2rem solid #791e09;
                padding-left: 1rem;
                border-radius: .8rem;
                -webkit-border-radius: .8rem;
                -moz-border-radius: .8rem;
                -ms-border-radius: .8rem;
                -o-border-radius: .8rem;
            }

            & textarea{
                border: .2rem solid #791e09;
                padding-left: 1rem;
                border-radius: .8rem;
            }
        }

        & .contact-button {
            display: flex;
            justify-content: center;

            & .btn {
                background-color: red;
                font-size: 1.6rem;
                font-weight: 500;
                width: 10rem;
                color: white;
                padding: 8px 16px;
                border: none;
                border-radius: 4px;
                cursor: pointer;
            }
        }
    }
}

@media (width <=750px) {
    .ContactUs {
        .form {
            & .two-row {
                grid-template-columns: 1fr;
            }
        }
    }

}

@media (width <=420px) {
    .ContactUs {
        width: 100%;

        .form {
            & .two-row {
                grid-template-columns: 1fr;
            }
        }
    }
}

@media (width <=700px) {
    .ContactUs {
        .contact-details {
            gap: 1rem;
        }
    }
}