.Footer {
    background-color: #22262a;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    padding: 2rem 7rem;
    overflow: hidden;

    & .information {
        display: flex;
        flex-direction: column;

        & .main-logo {
            display: flex;

            & .logo-image {
                width: auto;
                height: 5rem;
            }

            & .text-image {
                width: auto;
                height: 5rem;
            }
        }

        & .info {
            font-size: 1.4rem;
            color: white;
            text-align: justify;
        }
    }

    & .footer-contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;

        & .contact {
            & h6 {
                font-size: 2.4rem;
            }

            & p {
                font-size: 1.6rem;
            }

            & .sep {
                width: 15rem;
                height: .5rem;
                background-color: white;
                margin: 1rem 0;
            }
        }

        & .social {
            display: flex;
            margin-top: 1rem;
            gap: 1rem;

            & a {
                & img {
                    width: 3rem;
                }
            }
        }
    }
}

@media (width <=950px) {
    .Footer {
        grid-template-columns: 1fr;

        & .information {

            & .main-logo {

                & .logo-image {
                    width: auto;
                    height: 3rem;
                }

                & .text-image {
                    width: auto;
                    height: 3rem;
                }
            }

        }
    }
}

section {
    position: relative;
}