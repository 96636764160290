@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');


*, *::before, *::after {
  box-sizing: border-box;
}

body {
  /* font-family: 'Lato', sans-serif;
  background-color: #ded2ff;
  color: #42225e; */
  margin: 0;
  padding: 0;
}


h1 {
  margin: 50px 0 30px;
  text-align: center;
  font-size: 30px;
  
}
p{
  font-size:xx-small small;
}

.faq-container {
  max-width: 90%; 
  margin: 0 auto;
  padding: 0 15px; 
}


.faq {
  background-color: transparent;
  border: 1px solid #791e09;
  border-radius: 10px;
  padding: 15px;
  margin: 20px 0;
  position: relative;
  overflow: hidden;
  transition: all .4s ease;
  font-size: 15px;
}


.faq.active {
  background-color: #f4f3ee;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
}


.faq-title {
  margin: 0 35px 0 0;
}


.faq-text {
  display: none;
  margin: 30px 0 0;
  font-size: larger;
}


.faq.active .faq-text {
  display: block;
}


.faq-toggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 10px; 
  right: 10px; 
  height: 30px;
  width: 30px;
  z-index: 1; 
}


.faq-toggle i {
  width: 12px;
  height: 12px;
}

.faq-toggle .close {
  display: none;
}

.faq.active .faq-toggle .close {
  display: block;
}

.faq.active .faq-toggle .chevron {
  display: none;
}

.faq.active .faq-toggle {
  background-color: #5f0a0a73;
  border-radius: 50%;
  color: #f1f1ec;
}
