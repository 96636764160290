.CollegeMessage {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 2rem 7rem;
}

.colleges {
    background-color: #f9f9f9;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.colleges:hover {
    transform: translateY(-5px);
}
.colleges {
    display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: center; 
  }
  
  .colleges .college-link {
    display: block;
    text-align: center;
    margin-bottom: 20px; 
  }
  
  .colleges .college-link img {
    width: 200px; 
    height: auto; 
  }
  

.colleges img {
    width: 15rem;
    margin-bottom: 1.5rem;
}

.colleges p {
    font-size: 1.4rem;
    line-height: 1.6;
    text-align: center;
    color: #555;
}

@media (max-width: 950px) {
    .CollegeMessage {
        grid-template-columns: 1fr;
    }
}
