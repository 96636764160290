/* Base styles */
.Event {
  display: flex;
  flex-direction: column;
  margin: 2rem 7rem;
}

.event-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.4rem; 
}

h3 {
  font-size: 3.2rem;
  text-align: center;
  margin-top: 2.4rem;
  font-weight: bold; 
  color: #333; 
}

p {
  font-size: 2rem;
  text-align: center; 
  margin: 0.8rem 0;
  line-height: 1.6; 
  color: #666; 
}

.sep {
  width: 20rem;
  height: 1rem;
  background-color: black;
  border-radius: 1rem;
  margin-top: 1.2rem; 
}

.google-map-code {
  width: 100%;
  height: 300px;
  margin-top: 2.4rem; 
}

iframe {
  width: 100%;
  height: 100%;
  border: none; 
}

/* /Schedule styles */
.schedule {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
}

.day {
  margin-right: 30px;
}

.day:last-child {
  margin-right: 0;
}

.day h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 15px;
}

.event-group {
  margin-bottom: 20px;
  cursor: default; 
}

.event-title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  color: #ff2700;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 5px;
  font-size: 16px;
}


.event-group {
  border: 2px solid #ff8400;
  border-radius: 10px;
  padding: 15px;
  background-color: #ffe3b9;
  /* box-shadow: 0px 0px 15px rgba(0, 123, 255, 0.3); */
  transition: transform 0.3s ease;
}

.event-group:hover {
  transform: scale(1.03);
}

.event-title {
  text-shadow: 2px 2px 4px rgba(231, 125, 19, 0.3);
  transition: color 0.3s ease;
}

.event-title:hover {
  color: #de5a26;
}

ul li {
  color: #333;
  transition: color 0.3s ease;
}

ul li:hover {
  color: #de5a26;
} 

@media (width<=940px) {
  .schedule {
    flex-direction: column;
  }
}